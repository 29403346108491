import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

function Agency() {
    return (
        <div className="main_view">
            <Navbar></Navbar>

            <div className="agency_cont">
                <div className="agency_img_cont">
                    <div className="agency_img"></div>
                </div>
                <div className="agency_desc">
                    <p className="agency_desc_title">La nostra agenzia</p>
                    <p className="agency_desc_desc">Stilnovo Immobiliare, da 30 anni nel settore con Agenzie in Lombardia e Piemonte, offre valutazione gratuita dell'immobile, consulenza finanziaria, ristrutturazioni, certificazioni energetiche, vendite e affitti. Affidati al nostro gruppo, la qualità e l'impegno sono al vostro servizio

                    L'obbiettivo è la soddisfazione del cliente che viene seguito personalmente fino all'atto notarile nell'assoluto rispetto della privacy</p>
                </div>
            </div>

            <Footer></Footer>
        </div>
    )
}

export default Agency