import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, signInWithEmailAndPassword } from "./Firebase"; // Adjust the path if needed
import Spinner from "../components/Spinner";

function Login() {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [err, setErr] = useState({ message: "", display: false });
    const [loading, setLoading] = useState(false);

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-])[A-Za-z\d@$!%*?&-]{10,20}$/;

    const validateEmail = (email) => {
        return emailRegex.test(email);
    };

    const validatePassword = (password) => {
        return passwordRegex.test(password);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            startLogin();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    function startLogin() {
        setErr({ message: "", display: false })
        const email_reg = document.getElementById("email_reg");
        const password_reg = document.getElementById("password_reg");

        let error = false;
        let error_email = false;
        let error_password = false;

        if (email_reg.value === "") {
            error = true;
            email_reg.style.border = "1px solid var(--danger)";
        } else if (!validateEmail(email_reg.value)) {
            error = true;
            error_email = true;
            email_reg.style.border = "1px solid var(--danger)";
        } else {
            email_reg.style.border = "";
        }

        if (password_reg.value === "") {
            error = true;
            password_reg.style.border = "1px solid var(--danger)";
        } else if (!validatePassword(password_reg.value)) {
            error = true;
            error_password = true;
            password_reg.style.border = "1px solid var(--danger)";
        } else {
            password_reg.style.border = "";
        }

        if (error) {
            if (error_email) {
                setErr({ message: "Controlla il formato della mail...", display: true });
            } else if (error_password) {
                setErr({ message: "La password deve contenere Maiuscole, Minuscole, numeri e caratteri speciali...", display: true });
            } else {
                setErr({ message: "Controlla i campi in rosso...", display: true });
            }
        } else {
            loginUser(email_reg.value, password_reg.value);
            setErr({ message: "", display: false });
        }
    }

    function loginUser(email, password) {
        setLoading(true);

        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                setLoading(false);
                const user = userCredential.user;
                sessionStorage.setItem("user_id", user.uid);
                navigate("/dashboard");
            })
            .catch((error) => {
                setLoading(false);
                console.error("Error signing in: ", error.message);
                if (error.code === 'auth/invalid-email' || error.code === 'auth/user-not-found') {
                    setErr({ message: "Username o password non validi...", display: true });
                } else if (error.code === 'auth/wrong-password') {
                    setErr({ message: "Password errata...", display: true });
                } else {
                    setErr({ message: "Qualcosa è andato storto... contatta il supporto", display: true });
                }
            });
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="main_frame">
            <div className="register_page">
                <div className="register_conts_reg">
                    <div className="reg_cont">
                        <p className="title_form">Stilnovo Immobiliare </p>
                        <input type="text" id="email_reg" placeholder="Email" className="register_form_first" />
                        <div className="password_eye">
                            <input type={showPassword ? "text" : "password"} id="password_reg" placeholder="Password" className="register_form_first" />
                            <button className="show_passowrd" onClick={togglePasswordVisibility}>
                                <i className={`fa-regular fa-eye${showPassword ? "" : "-slash"}`}></i>
                            </button>
                        </div>
                        <button onClick={startLogin} className="start_btn">Accedi</button>
                        <p className="already_present">Powered by <span className="ia_span">FlatJourneyAI</span></p>
                    </div>
                    <Spinner loading={loading} err={err} />
                </div>
            </div>
        </div>
    );
}

export default Login;
