import React from "react";

const PrivacyPolicy = () => {
    return (
        <div className="privacy_policy_page">
            <hr />
            <h2>INFORMATIVA SULLA PRIVACY</h2>
            <p><strong>Ultimo aggiornamento:</strong> 16/09/2024</p>

            <h3>1. Titolare del Trattamento dei Dati</h3>
            <p>
                <strong>Stilnovo Immobiliare</strong><br />
                Indirizzo: Via Novara 21, 20020, Vanzaghello (Mi)<br />
                Telefono: +39 392 947 4334 - Email: <a href="mailto:toromannu@libero.it">toromannu@libero.it</a>
            </p>

            <h3>2. Tipologie di Dati raccolti</h3>
            <p>Attraverso il nostro sito web, raccogliamo le seguenti informazioni personali:</p>
            <ul>
                <li>Nome</li>
                <li>Cognome</li>
                <li>Numero di telefono</li>
                <li>Indirizzo email</li>
                <li>Descrizione delle necessità immobiliari</li>
            </ul>

            <h3>3. Finalità del trattamento dei dati</h3>
            <p>
                I dati personali forniti volontariamente dagli utenti attraverso il nostro sito vengono trattati per le seguenti finalità:
            </p>
            <ul>
                <li>Rispondere alle richieste di informazioni sui nostri servizi immobiliari;</li>
                <li>Fornire consulenza in relazione alle esigenze immobiliari indicate;</li>
                <li>Contattare gli utenti per eventuali proposte immobiliari in linea con le richieste specificate;</li>
                <li>Adempiere a obblighi legali, amministrativi o contabili connessi alla nostra attività.</li>
            </ul>

            <h3>4. Base giuridica del trattamento</h3>
            <p>Il trattamento dei dati personali si basa:</p>
            <ul>
                <li>Sull'esecuzione di un contratto o misure precontrattuali (art. 6, comma 1, lett. b del GDPR);</li>
                <li>Sul consenso espresso dall'utente per le finalità di contatto (art. 6, comma 1, lett. a del GDPR);</li>
                <li>Sugli obblighi legali del titolare (art. 6, comma 1, lett. c del GDPR).</li>
            </ul>

            <h3>5. Modalità del trattamento</h3>
            <p>
                I dati personali sono trattati con strumenti elettronici e cartacei nel rispetto delle misure di sicurezza previste dalle normative vigenti per garantire la riservatezza e la protezione dei dati personali.
            </p>

            <h3>6. Comunicazione dei dati</h3>
            <p>
                I dati personali degli utenti non saranno comunicati a terzi, salvo per obblighi di legge o in caso di specifico consenso da parte dell'interessato.
            </p>

            <h3>7. Conservazione dei dati</h3>
            <p>
                I dati personali verranno conservati per il tempo strettamente necessario al conseguimento delle finalità sopra indicate e, in ogni caso, per un periodo non superiore a <strong>[specifica il tempo di conservazione, ad es. 24 mesi]</strong> dalla raccolta, salvo che l'utente non richieda la cancellazione dei dati in precedenza.
            </p>

            <h3>8. Diritti dell'interessato</h3>
            <p>Gli utenti hanno il diritto di:</p>
            <ol>
                <li>Ottenere la conferma dell'esistenza o meno di dati personali che li riguardano;</li>
                <li>Accedere ai propri dati personali;</li>
                <li>Richiedere la rettifica o la cancellazione dei dati;</li>
                <li>Richiedere la limitazione del trattamento o opporsi al trattamento;</li>
                <li>Richiedere la portabilità dei dati;</li>
                <li>Revocare il consenso in qualsiasi momento, senza pregiudicare la liceità del trattamento basato sul consenso prestato prima della revoca;</li>
                <li>Proporre reclamo all'autorità di controllo (Garante per la Protezione dei Dati Personali).</li>
            </ol>
            <p>
                Per esercitare tali diritti, è possibile inviare una richiesta via email a: <a href="mailto:toromannu@libero.it">toromannu@libero.it</a>.
            </p>

            <h3>9. Modifiche all'informativa</h3>
            <p>
                Ci riserviamo il diritto di apportare modifiche alla presente informativa sulla privacy in qualsiasi momento. Eventuali modifiche verranno pubblicate su questa pagina e avranno effetto immediato.
            </p>

            <h3>10. Contatti</h3>
            <p>
                Per qualsiasi informazione riguardante questa informativa, è possibile contattare il titolare del trattamento ai seguenti recapiti:<br />
                Email: <a href="mailto:toromannu@libero.it">toromannu@libero.it</a><br />
                Telefono: +39 392 947 4334
            </p>
            <hr />
        </div>
    );
};

export default PrivacyPolicy;
