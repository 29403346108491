// Footer.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate()

  const navigateLogin = () =>{
      navigate("/login")
  }

  return (
    <div className='footer'>
        <p className='link_footer' onClick={navigateLogin}>Stilnovo Immobiliare</p>
        <p>Via Novara 21 , 20020, Vanzaghello (Mi)</p>
        <p>Telefono: +39 392 947 4334 - Email: toromannu@libero.it </p>
        <p>Via IV Novembre, 36, 28068 Romentino (NO)</p>
        <p>Telefono: +39 392 947 4144 - Email: stilnovoweb@libero.it</p>
        <p className='powered_by_fj'>Powered by FlatJourneyAI</p>
    </div>
  );
};



export default Footer;
