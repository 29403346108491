import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { database, ref, push, update } from './Firebase'; // Ensure this path is correct
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid'; // To generate unique IDs
import comuniJson from "../Json/gi_comuni.json"; // Assuming this is your JSON file

function AddPostModal({ show, handleClose, editPost, existingPostData }) {
    const [type, setType] = useState('');
    const [province, setProvince] = useState('');
    const [municipality, setMunicipality] = useState('');
    const [status, setStatus] = useState('');
    const [price, setPrice] = useState('');
    const [mt2, setMt2] = useState('');
    const [description, setDescription] = useState('');
    const [pubblic, setPubblic] = useState(false);
    const [images, setImages] = useState([]); // State for multiple image files
    const [uploading, setUploading] = useState(false); // State for upload progress
    const [imageURLs, setImageURLs] = useState([]); // Store existing image URLs for editing
    const [filteredMunicipalities, setFilteredMunicipalities] = useState([]); // State for filtered municipalities

    // Firebase Storage reference
    const storage = getStorage();

    // Populate fields with existing post data when editing
    useEffect(() => {
        if (existingPostData) {
            setType(existingPostData.type || '');
            setProvince(existingPostData.province || '');
            setMunicipality(existingPostData.municipality || '');
            setStatus(existingPostData.status || '');
            setPrice(existingPostData.price || '');
            setMt2(existingPostData.mt2 || '');
            setDescription(existingPostData.description || '');
            setPubblic(existingPostData.pubblic || false);
            setImageURLs(existingPostData.imageURLs || []); // Existing images
        }
    }, [existingPostData]);

    // Extract unique provinces
    const provinces = [...new Set(comuniJson.map(comune => comune.sigla_provincia))];

    // Handle province change and filter municipalities
    const handleProvinceChange = (e) => {
        const selectedProvince = e.target.value;
        setProvince(selectedProvince);

        // Filter municipalities based on the selected province
        const filtered = comuniJson
            .filter(comune => comune.sigla_provincia === selectedProvince)
            .map(comune => comune.denominazione_ita);
        setFilteredMunicipalities(filtered);
        setMunicipality(''); // Reset municipality selection when province changes
    };

    // Handle file selection
    const handleFileChange = (event) => {
        if (event.target.files) {
            setImages([...event.target.files]);
        }
    };

    // Upload multiple images to Firebase Storage and get the download URLs
    const uploadImages = async (files) => {
        setUploading(true);
        const uploadPromises = files.map(file => {
            const imageId = uuidv4(); // Generate a unique ID for the image
            const fileName = `${imageId}_${Math.floor(Math.random() * 10000)}_${file.name}`;
            const imageRef = storageRef(storage, `images/${fileName}`);
            return uploadBytes(imageRef, file).then(() => getDownloadURL(imageRef));
        });

        try {
            const newImageURLs = await Promise.all(uploadPromises);
            setUploading(false);
            return newImageURLs;
        } catch (error) {
            console.error('Error uploading images:', error);
            setUploading(false);
            return [];
        }
    };

    // Remove image from Firebase Storage and from imageURLs array
    const handleRemoveImage = (imageUrl) => {
        const imageRef = storageRef(storage, imageUrl);

        deleteObject(imageRef)
            .then(() => {
                console.log('Image deleted successfully');
                const updatedImageURLs = imageURLs.filter(url => url !== imageUrl);
                setImageURLs(updatedImageURLs);
            })
            .catch((error) => {
                console.error('Error deleting image:', error);
            });
    };

    // Handle form submission (Add or Edit)
    const handleSubmit = async () => {
        let newImageURLs = imageURLs; // Start with existing images
        if (images.length > 0) {
            const uploadedImages = await uploadImages(images);
            newImageURLs = [...newImageURLs, ...uploadedImages];
        }

        const postData = {
            type,
            province,
            municipality,
            status,
            price,
            mt2,
            description,
            pubblic,
            imageURLs: newImageURLs, // Include updated image URLs
        };

        if (editPost) {
            const postRef = ref(database, `posts/${existingPostData.id}`);
            update(postRef, postData)
                .then(() => {
                    console.log('Post updated successfully!');
                    handleCloseModal();
                })
                .catch(error => {
                    console.error('Error updating post:', error);
                });
        } else {
            const postsRef = ref(database, 'posts');
            push(postsRef, postData)
                .then(() => {
                    console.log('Post created successfully!');
                    handleCloseModal();
                })
                .catch(error => {
                    console.error('Error saving post:', error);
                });
        }
    };

    const handleCloseModal = () => {
        setType('');
        setProvince('');
        setMunicipality('');
        setStatus('');
        setPrice('');
        setMt2('');
        setDescription('');
        setPubblic(false);
        setImages([]);
        setImageURLs([]);

        handleClose();
    };

    return (
        <Modal show={show} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>{editPost ? 'Modifica Annuncio' : 'Nuovo Annuncio'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="input_card_modale">
                    <p className="filter_labels">Tipologia</p>
                    <select className="filter_input" value={type} onChange={(e) => setType(e.target.value)}>
                        <option value="">-</option>
                        <option value={"Appartamento"}>Appartamento</option>
                        <option value={"Attività commerciale"}>Attività commerciale</option>
                        <option value={"Nifamiliare"}>Bifamiliare</option>
                        <option value={"Casa Singola"}>Casa singola</option>
                        <option value={"Garage"}>Garage</option>
                        <option value={"Magazzino"}>Magazzino</option>
                        <option value={"Negozio"}>Negozio</option>
                        <option value={"Posto auto"}>Posto auto</option>
                        <option value={"Rustico"}>Rustico</option>
                        <option value={"Terreno"}>Terreno</option>
                        <option value={"Ufficio"}>Ufficio</option>
                        <option value={"Villa"}>Villa</option>
                    </select>

                    <p className="filter_labels">Provincia</p>
                    <select className="filter_input" value={province} onChange={handleProvinceChange}>
                        <option value="">-</option>
                        {provinces.map((prov, idx) => (
                            <option key={idx} value={prov}>{prov}</option>
                        ))}
                    </select>

                    <p className="filter_labels">Comune</p>
                    <select className="filter_input" value={municipality} onChange={(e) => setMunicipality(e.target.value)}>
                        <option value="">-</option>
                        {filteredMunicipalities.map((comune, idx) => (
                            <option key={idx} value={comune}>{comune}</option>
                        ))}
                    </select>

                    <p className="filter_labels">Vendita/Affitto</p>
                    <select className="filter_input" value={status} onChange={(e) => setStatus(e.target.value)}>
                        <option value="">-</option>
                        <option value="Vendita">Vendita</option>
                        <option value="Affitto">Affitto</option>
                    </select>

                    <p className="filter_labels">Prezzo</p>
                    <input
                        type="number"
                        className="filter_input"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                    />

                    <p className="filter_labels">Metri quadri</p>
                    <input
                        type="number"
                        className="filter_input"
                        value={mt2}
                        onChange={(e) => setMt2(e.target.value)}
                    />

                    <p className="filter_labels">Descrizione</p>
                    <input
                        className="filter_input"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />

                    <p className="filter_labels">Immagini</p>
                    <input
                        type="file"
                        className="filter_input_file"
                        accept="image/*"
                        multiple
                        onChange={handleFileChange}
                    />

                    {imageURLs.length > 0 && (
                        <div className="existing-images">
                            <div className='img_cont_prev'>
                                {imageURLs.map((url, index) => (
                                    <div className='img_prev' key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={url} alt="preview" style={{ width: '100px', marginRight: '10px' }} />
                                        <button className='remove_img_btn' onClick={() => handleRemoveImage(url)}>
                                            X
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {uploading && <p>Caricamento...</p>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Chiudi
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    {editPost ? 'Aggiorna' : 'Salva'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddPostModal;
