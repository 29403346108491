import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const CustomNavbar = () => {
    return (
        <Navbar expand="lg" className="nav_bar">
            <Container className='nav_cont'>
                <Navbar.Brand as={Link} to="/" className='logo'>
                    <div className='logo_cont_script'>
                        <p>Stilnovo Immobiliare</p>
                        {/* <div className='logo_main'></div> */}
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className='toggle_btn' />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/">Home</Nav.Link>
                        <Nav.Link as={Link} to="/immobili-in-vendita">Immobili in vendita</Nav.Link>
                        <Nav.Link as={Link} to="/servizi">Servizi</Nav.Link>
                        <Nav.Link as={Link} to="/agenzia">Agenzia</Nav.Link>
                    </Nav>
                    <div className='logo_cont'>
                        <div className='logo_main'></div>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default CustomNavbar;
