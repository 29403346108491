// firebase.js
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, push, onValue,remove, update  } from 'firebase/database';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

// Replace the following with your Firebase project's configuration
const firebaseConfig = {
    apiKey: "AIzaSyCT3yozoQ1x1eU7Bco15ZYf8j4f8I_rAhM",
    authDomain: "re-0001-aa832.firebaseapp.com",
    databaseURL: "https://re-0001-aa832-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "re-0001-aa832",
    storageBucket: "re-0001-aa832.appspot.com",
    messagingSenderId: "356216538360",
    appId: "1:356216538360:web:b18482c6146df76454a70d",
    measurementId: "G-3SS4T1W269"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);

export { auth, database, ref, push, onValue,remove , update, signInWithEmailAndPassword };
