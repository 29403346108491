import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { database, ref, push } from './Firebase';
import axios from 'axios';

function FormContact() {
    const [formData, setFormData] = useState({
        nome: '',
        cognome: '',
        email: '',
        telefono: '',
        messaggio: '',
    });

    const [isChecked, setIsChecked] = useState(false); // State for checkbox
    const [checkboxError, setCheckboxError] = useState(false); // State to track if the checkbox error should be displayed
    const formRef = useRef(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
        if (e.target.checked) {
            setCheckboxError(false); // If the checkbox is checked, hide the error
        }
    };

    const sendEmail = async () => {
        const apiKey = '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'; // Use your actual Brevo API key

        const emailPayload = {
            sender: { email: 'pirecizyber@gmail.com', name: 'FlatJourneyAI' },
            to: [{ email: 'toromannu@libero.it', name: 'Stilnovo Immobiliare' }],
            subject: 'Nuova richiesta di informazioni',
            htmlContent: `
                <p>E' arrivata una nuova richiesta di informazioni.</p>
                <p><strong>Nome:</strong> ${formData.nome}</p>
                <p><strong>Cognome:</strong> ${formData.cognome}</p>
                <p><strong>Email:</strong> ${formData.email}</p>
                <p><strong>Telefono:</strong> ${formData.telefono}</p>
                <p><strong>Messaggio:</strong> ${formData.messaggio}</p>
            `,
        };

        try {
            const response = await axios.post('https://api.brevo.com/v3/smtp/email', emailPayload, {
                headers: {
                    'Content-Type': 'application/json',
                    'api-key': apiKey,
                },
            });
            console.log('Email sent successfully', response.data);
        } catch (error) {
            console.error('Error sending email', error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Check if the checkbox is checked
        if (!isChecked) {
            setCheckboxError(true);
            return; // Prevent form submission if the checkbox isn't checked
        }

        const contactsRef = ref(database, 'contacts');

        push(contactsRef, formData)
            .then(() => {
                alert('Messaggio inviato con successo!');
                setFormData({
                    nome: '',
                    cognome: '',
                    email: '',
                    telefono: '',
                    messaggio: '',
                });
                setIsChecked(false); // Reset the checkbox
                sendEmail(); // Send email after successful submission
            })
            .catch(error => {
                console.error('Errore durante l\'invio del messaggio:', error);
            });
    };

    return (
        <div className='main_form'>
            <p className='title_form'>Contattaci!</p>
            <form onSubmit={handleSubmit}>
                <div className='form-row'>
                    <input
                        type='text'
                        placeholder='Nome'
                        className='input'
                        name='nome'
                        value={formData.nome}
                        onChange={handleChange}
                    />
                    <input
                        type='text'
                        placeholder='Cognome'
                        className='input'
                        name='cognome'
                        value={formData.cognome}
                        onChange={handleChange}
                    />
                </div>
                <div className='form-row'>
                    <input
                        type='email'
                        placeholder='Email'
                        className='input'
                        name='email'
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <input
                        type='text'
                        placeholder='Telefono'
                        className='input'
                        name='telefono'
                        value={formData.telefono}
                        onChange={handleChange}
                    />
                </div>
                <div className='form-row'>
                    <textarea
                        placeholder='Lascia un messaggio...'
                        className='textarea'
                        name='messaggio'
                        value={formData.messaggio}
                        onChange={handleChange}
                    ></textarea>
                </div>
                <div className='privacy_cont'>
                    <input
                        type='checkbox'
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                    />
                    <label
                        style={{
                            color: checkboxError ? 'red' : 'inherit'
                        }}
                    >
                        Dichiaro di aver letto e compreso l'<Link to="/privacy_policy">Informativa sulla Privacy</Link> e acconsento al trattamento dei miei dati personali per le finalità indicate
                    </label>
                </div>
                <button type='submit' className='submit-button'>Invia</button>
            </form>
        </div>
    );
}

export default FormContact;
