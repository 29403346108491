import React, { useState, useEffect, useRef } from 'react';
import Footer from './Footer';
import AddPostModal from './AddPostModal';
import { database, ref, onValue, update, remove } from './Firebase';
import Table from 'react-bootstrap/Table';
import { getStorage, ref as storageRef, deleteObject } from 'firebase/storage'; // Import Firebase storage
import { useNavigate } from 'react-router-dom';


function Dashboard() {
    const navigate = useNavigate()
    const [modalShow, setModalShow] = useState(false);
    const [posts, setPosts] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [activeSection, setActiveSection] = useState('posts'); // State to manage active section
    const [editPost, setEditPost] = useState(false); // New state for edit mode
    const [selectedPost, setSelectedPost] = useState(null); //
    const imgContainersRef = useRef({});
    const nav = useNavigate()

    const handleShow = () => setModalShow(true);
    const handleClose = () => setModalShow(false);

    useEffect(() => {
        // Fetch data from Firebase Realtime Database
        const postsRef = ref(database, 'posts');
        onValue(postsRef, (snapshot) => {
            const data = snapshot.val();
            const postsArray = [];

            for (let id in data) {
                postsArray.push({ id, ...data[id] });
            }

            setPosts(postsArray);
        });
    }, []);

    useEffect(() => {
        const userId = sessionStorage.getItem('user_id');
        if (!userId) {
            navigate("/login")
        }
    }, []); 

    useEffect(() => {
        // Fetch contacts data only when the "contacts" section is active
        if (activeSection === 'contacts') {
            const contactsRef = ref(database, 'contacts');
            onValue(contactsRef, (snapshot) => {
                const data = snapshot.val();
                const contactsArray = [];

                for (let id in data) {
                    contactsArray.push({ id, ...data[id] });
                }

                setContacts(contactsArray);
            });
        }
    }, [activeSection]);

    const handleDelete = (postId, imageUrls = []) => {
        // Firebase Storage reference
        const storage = getStorage();

        // Check if imageUrls is a valid array
        if (!Array.isArray(imageUrls) || imageUrls.length === 0) {
            console.log('No images to delete, proceeding to delete the post.');
            // If no images, delete the post directly
            const postRef = ref(database, `posts/${postId}`);
            return remove(postRef)
                .then(() => {
                    console.log('Post deleted successfully');
                    setPosts(posts.filter(post => post.id !== postId));
                })
                .catch(error => {
                    console.error('Error deleting post:', error);
                });
        }

        // Delete all images from Firebase Storage
        const deleteImagePromises = imageUrls.map((url) => {
            const imgRef = storageRef(storage, url);
            return deleteObject(imgRef)
                .then(() => {
                    console.log(`Image deleted successfully: ${url}`);
                })
                .catch((error) => {
                    console.error(`Error deleting image: ${url}`, error);
                });
        });

        // Wait for all images to be deleted, then delete the post
        Promise.all(deleteImagePromises)
            .then(() => {
                // Now delete the post from Firebase Database
                const postRef = ref(database, `posts/${postId}`);
                return remove(postRef);
            })
            .then(() => {
                console.log('Post deleted successfully');
                setPosts(posts.filter(post => post.id !== postId));
            })
            .catch(error => {
                console.error('Error deleting post:', error);
            });
    };


    const handlePublish = (postId) => {
        const postRef = ref(database, `posts/${postId}`);
        update(postRef, { pubblic: true })
            .then(() => {
                console.log('Post published successfully');
                setPosts(posts.map(post =>
                    post.id === postId ? { ...post, pubblic: true } : post
                ));
            })
            .catch(error => {
                console.error('Error publishing post:', error);
            });
    };

    const handleUnpublish = (postId) => {
        const postRef = ref(database, `posts/${postId}`);
        update(postRef, { pubblic: false })
            .then(() => {
                console.log('Post unpublished successfully');
                setPosts(posts.map(post =>
                    post.id === postId ? { ...post, pubblic: false } : post
                ));
            })
            .catch(error => {
                console.error('Error unpublishing post:', error);
            });
    };

    const scroll = (containerId, direction) => {
        const container = imgContainersRef.current[containerId];
        if (container) {
            container.scrollBy({
                left: direction === 'left' ? -200 : 200,
                behavior: 'smooth'
            });
        }
    };

    const handleEdit = (post) => {
        setSelectedPost(post);
        setEditPost(true);
        setModalShow(true); 
    };

    const handleLogout = () => {
        sessionStorage.removeItem("user_id")
        nav("/")
    }

    return (
        <div className="dashBoard_cont">
            <div className="nav_main">
                <div className='nav_main_container'>

                    <p
                        className="annunci_disponibili"
                        onClick={() => setActiveSection('posts')}
                    >
                        <i className="fa-solid fa-sign-hanging"></i> Gestione annunci
                    </p>
                    <p
                        className="annunci_disponibili"
                        onClick={() => setActiveSection('contacts')}
                    >
                        <i className='fa-solid fa-users'></i> Gestione Contatti
                    </p>
                </div>
                <div className='nav_main_container'>
                    <p className='logout_btn' onClick={handleLogout}><i className="fa-solid fa-right-from-bracket"></i> Esci</p>
                </div>
            </div>
            <div className="main_cont">
                {activeSection === 'posts' && (
                    <div className="manage_posts_cont">
                        <div className="function_bar">
                            <button className="add_post_btn" onClick={handleShow}>
                                <i className="fa-solid fa-plus"></i> Aggiungi annuncio
                            </button>
                        </div>
                        <div className="post_container">
                            {posts.map(post => (
                                <div key={post.id} className="card_post">
                                    <div className="img_cont">
                                        <div className='scrol_btn_cont'>
                                            <button
                                                className="scroll_button left"
                                                onClick={() => scroll(post.id, 'left')}
                                            >
                                                <i className="fa-solid fa-chevron-left"></i>
                                            </button>
                                            <button
                                                className="scroll_button right"
                                                onClick={() => scroll(post.id, 'right')}
                                            >
                                                <i className="fa-solid fa-chevron-right"></i>
                                            </button>
                                        </div>
                                        <div
                                            className="img_wrapper"
                                            ref={el => (imgContainersRef.current[post.id] = el)}
                                        >
                                            {post.imageURLs && post.imageURLs.map((url, index) => (
                                                <div
                                                    key={index}
                                                    className='img_shown'
                                                    style={{ backgroundImage: `url(${url})` }}
                                                ></div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="info_cont">
                                        <div className="price_row">
                                            <div className="btn_cont_price">
                                                <button
                                                    className="price_btn_change"
                                                    onClick={() => handleEdit(post)}
                                                >
                                                    <i className="fa-solid fa-pencil"></i>
                                                </button>
                                                <button
                                                    className="price_btn_delete"
                                                    onClick={() => handleDelete(post.id)}
                                                >
                                                    <i className="fa-solid fa-trash"></i>
                                                </button>
                                                {post.pubblic ? (
                                                    <button
                                                        className="price_btn_unpublish"
                                                        onClick={() => handleUnpublish(post.id)}
                                                    >
                                                        <p>Rimuovi annuncio</p>
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="price_btn_publish"
                                                        onClick={() => handlePublish(post.id)}
                                                    >
                                                        <p>Pubblica annuncio</p>
                                                    </button>
                                                )}
                                            </div>
                                            <div className="sell_rent">{post.status}</div>
                                            <div className="price">{post.price}€</div>
                                        </div>
                                        <div className="det_row">
                                            <div className="details_desc_title">Descrizione</div>
                                            <div className="details_desc">
                                                {post.description}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {activeSection === 'contacts' && (
                    <div className="manage_contacts_cont">
                        <Table className='table' striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Cognome</th>
                                    <th>Email</th>
                                    <th>Telefono</th>
                                    <th>Messaggio</th>
                                </tr>
                            </thead>
                            <tbody>
                                {contacts.map(contact => (
                                    <tr key={contact.id}>
                                        <td>{contact.nome}</td>
                                        <td>{contact.cognome}</td>
                                        <td>{contact.email}</td>
                                        <td>{contact.telefono}</td>
                                        <td>{contact.messaggio}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                )}
            </div>
            <Footer />
            <AddPostModal
                show={modalShow}
                handleClose={handleClose}
                editPost={editPost} // Pass edit mode
                existingPostData={selectedPost} // Pass post data to edit
            />
        </div>
    );
}

export default Dashboard;
