import React, { useState, useEffect, useRef } from "react";
import Navbar from "./Navbar";
import FilterPosts from "./FilterPosts";
import Footer from "./Footer";
import { MdOutlineZoomOutMap } from "react-icons/md";
import { database, ref, onValue } from './Firebase'; // Adjust the path to Firebase
import FullImgModal from './FullImgModal'; // Import FullImgModal

function ImmobiliInVendita() {
    const [posts, setPosts] = useState([]);
    const [filteredPosts, setFilteredPosts] = useState([]);
    const [filters, setFilters] = useState({
        tipologia: "",
        provincia: "",
        comune: "",
        venditaAffitto: "",
        prezzoMin: "",
        prezzoMax: "",
        mt2: "",
    });
    const imgContainersRef = useRef({});

    const [showModal, setShowModal] = useState(false);
    const [currentImages, setCurrentImages] = useState([]);
    const [currentImgIndex, setCurrentImgIndex] = useState(0);

    useEffect(() => {
        // Fetch data from Firebase Realtime Database
        const postsRef = ref(database, 'posts');
        onValue(postsRef, (snapshot) => {
            const data = snapshot.val();
            const postsArray = [];

            for (let id in data) {
                if (data[id].pubblic && data[id].status === "Vendita") { // Filter by public and status
                    postsArray.push({ id, ...data[id] });
                }
            }

            setPosts(postsArray);
            setFilteredPosts(postsArray); // Initialize filteredPosts with all posts
        });
    }, []);

    useEffect(() => {
        // Apply filters whenever the filter state changes
        const filtered = posts.filter(post => {
            return (
                (filters.tipologia === "" || post.type === filters.tipologia) &&
                (filters.provincia === "" || post.province === filters.provincia) &&
                (filters.comune === "" || post.municipality === filters.comune) &&
                (filters.venditaAffitto === "" || post.status === filters.venditaAffitto) &&
                (filters.prezzoMin === "" || post.price >= parseFloat(filters.prezzoMin)) &&
                (filters.prezzoMax === "" || post.price <= parseFloat(filters.prezzoMax)) &&
                (filters.mt2 === "" || post.mt2 <= parseFloat(filters.mt2))
            );
        });
        setFilteredPosts(filtered);
    }, [filters, posts]);

    const scroll = (containerId, direction) => {
        const container = imgContainersRef.current[containerId];
        if (container) {
            const imageWidth = container.querySelector('.img_shown').clientWidth; // Get the width of one image
            container.scrollBy({
                left: direction === 'left' ? -imageWidth : imageWidth,
                behavior: 'smooth'
            });
        }
    };

    const openFullImgModal = (images, index) => {
        setCurrentImages(images);
        setCurrentImgIndex(index);
        setShowModal(true);
    };

    return (
        <div className="main_view">
            <Navbar />
            <div className="container_card">
                <FilterPosts filters={filters} setFilters={setFilters} />

                <div className="card_container">
                    {filteredPosts.length === 0 ? (
                        <p className="no_posts">Nessun annuncio disponibile</p>
                    ) : (
                        filteredPosts.map(post => (
                            <div key={post.id} className="card_post">
                                <div className="img_cont">
                                    <div className='scrol_btn_cont'>
                                        <button
                                            className="scroll_button left"
                                            onClick={() => scroll(post.id, 'left')}
                                        >
                                            <i className="fa-solid fa-chevron-left"></i>
                                        </button>
                                        <button
                                            className="scroll_button right"
                                            onClick={() => scroll(post.id, 'right')}
                                        >
                                            <i className="fa-solid fa-chevron-right"></i>
                                        </button>
                                    </div>
                                    <div
                                        className="img_wrapper"
                                        ref={el => (imgContainersRef.current[post.id] = el)}
                                    >
                                        {post.imageURLs && post.imageURLs.map((url, index) => (
                                            <div
                                                key={index}
                                                className='img_shown'
                                                style={{ backgroundImage: `url(${url})` }}
                                                onClick={() => openFullImgModal(post.imageURLs, index)} // Open modal on click
                                            ></div>
                                        ))}
                                    </div>
                                </div>
                                <div className="info_cont">
                                    <div className="price_row">
                                        <div className="sell_rent">
                                            <p>{post.status}</p> 
                                        </div>
                                        <div className="price">
                                            {new Intl.NumberFormat('de-DE', { style: 'decimal' }).format(Number(post.price))}€
                                        </div>
                                    </div>
                                    <div className="det_row">
                                        <div className="details_desc_title">{post.type} - {post.mt2}mq</div>
                                        <div className="details_desc">
                                            {post.description}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>

            <Footer />

            <FullImgModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                imageURLs={currentImages}
                currentImgIndex={currentImgIndex}
                setCurrentImgIndex={setCurrentImgIndex}
            />
        </div>
    );
}

export default ImmobiliInVendita;
